<template>
  <b-card title="Hidden Course">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Hidden Course </span>
        </template>
        <Add @add-course="getCource" />
      </b-tab>

      <b-tab @click="$router.push('/all-hidden-course')">
        <template #title>
          <feather-icon icon="HomeIcon" data-testid="hidden-course-list" />
          <span>All Hidden Course</span>
        </template>
        <ALL ref="childComponentRef"  />
        <!-- <AllArticales></AllArticales> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import Add from './Add.vue'
import ALL from './All.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    Add,
    ALL,
  },
  data() {
    return {

    }
  },
  methods: {
    getCource() {
      this.$refs.childComponentRef.GetAllPosts()
    },
  },
}
</script>
